<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Daftar atau Buat Perjanjian'">
          <template v-slot:body>
            <Form
              :form="form"
              :route="'appointments'"
              :purpose="'add'" />
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/appointments/Form.vue'
import { getConfiguration } from '@/core/services/jwt.service.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  name: 'Add',

  components: {
    Card,
    Form
  },

  data() {
    return {
      form: {
        id: null,
        room_id: '',
        patient_id: '',
        doctor_id: '',
        action_type_id: '',
        date: '',
        doctor_time_slot_id: '',
        quote_start_time: '',
        quote_end_time: '',
        live_meeting_link: '',
        appointment_status_id: 1,
        notes: ''
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pendaftaran atau Perjanjian" },
      { title: "Daftar atau Buat Janji" },
    ])
    if (getConfiguration().appointment_confirmation == 1)
      this.form.appointment_status_id = 3
  }

}
</script>